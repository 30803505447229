import React, { FC, HTMLAttributes } from 'react';
import styled, { keyframes } from 'styled-components';

import machine from '../../../images/machine.png';
import bar from '../../../images/content-bar.png';
import wave from '../../../images/wave.png';
import nextbtn from '../../../images/next-button.png';

const rotate = keyframes`
    0% { transform: rotate(0); }
    100% {transform: rotate(360deg); }
`;

const Matchine_: FC<HTMLAttributes<HTMLImageElement>> = ({ ...props }) => <img src={machine} {...props} alt="" />;


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: url(${wave}), linear-gradient(180deg, #43A0D8 0%, #ACCF3A 100%);
    background-position: bottom center, center;
    background-repeat: no-repeat;
    overflow: auto;
`;

export const BarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 90px;
    background-color: #12A2DD;
    background-image: url(${bar});
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 20px;
    box-shadow: 0 0 55px #0eb8fd;
`;

export const Matchine = styled(Matchine_)`
    width: 61px;
    height: 61px;
    background-image: url(${machine});
    animation: ${rotate} 5s infinite linear;
`;

export const Title = styled.p`
    font-family: inherit;
    text-align: center;
    color: #221F1F;
    font-size: 36px;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 900px;
`;

export const ChoiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
`;

export const Radio = styled.span<{ active?: boolean }>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    left: 15px;
    border-radius: 50%;
    background-color: #12a2dd;

    &:before {
        content: " ";
        display: ${props => props.active ? 'block': 'none'};
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
    }
`;

export const Choice = styled.label<{ active?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 28px;
    width: 500px;
    height: 60px;
    padding-left: 80px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
    border-radius: 10px;

    &:active {
        background-color: rgba(255, 255, 255, 0.9);
    }
`;

export const ProcessContainer = styled.div`
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ProcessLine = styled.div`
    position: absolute;
    left: 50px;
    right: 50px;
    height: 10px;
    background-color: #12A2DD;
    border: 3px solid #fff;
`;

export const ProcessDot = styled.div<{active?: boolean}>`
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${props => props.active ? '#12A2DD' : '#C4C4C4'};
    border: 2px solid #fff;
    z-index: 9;
`;

export const NextButton = styled.button`
    display: block;
    border: none;
    outline: none;
    width: 105px;
    height: 105px;
    background-image: url(${nextbtn});
    background-color: transparent;
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    z-index: 99;
`;

NextButton.defaultProps = {
    type: 'button'
}