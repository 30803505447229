import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Array<number> = [];

const answer = createSlice({
    name: 'answer',
    initialState,
    reducers: {
        addAnswer(store, action: PayloadAction<number>) {
            return [
                ...store,
                action.payload,
            ];
        },
        resetAnswer() {
            return [];
        }
    }
});

export default answer.reducer;

export const { addAnswer, resetAnswer } = answer.actions;