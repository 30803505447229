import React, { FC, useEffect, useRef, useState  } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AppRoute from './route';
import { IStore, ITopicState } from './types';
import { fetchTopic } from './reducers/topic';
import Loaing from './components/loading';
import { resetAnswer } from './reducers/answer';


const App: FC = () => {

    const timer = useRef<any>(null);

    const topic = useSelector<IStore, ITopicState>(store => store.topic);
    const [time, setTime] = useState<number>(0);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    // console.log(time);

    useEffect(() => {

        if(topic.data.length < 1 && location.pathname !== '/') {
            history.replace('/');
        }

    }, [topic.data.length, history, location]);

    useEffect(() => {

        if(topic.data.length < 1) {
            dispatch(fetchTopic());
        }

    }, [topic.data.length, dispatch]);

    useEffect(() => {

        setTime(0);
        
        if(location.pathname !== '/') {
            restartTimer();
        }
        else {
            stopTimer();
        }

    }, [location]);

    useEffect(() => {

        if(time === 30) {
            dispatch(resetAnswer());
            history.replace('/');
        }

    }, [time, history, dispatch]);


    const restartTimer = () => {

        stopTimer();

        timer.current = setInterval(() => {
            setTime(prev => prev + 1);
        }, 1000);
    };

    const stopTimer = () => {
        if(timer.current) {
            clearInterval(timer.current);
        }
    };

    if(topic.loading) {
        return (<Loaing />);
    }

    return (<AppRoute />);
};

export default App;