import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { IStore, ITopicState } from '../../types';
import { Container, Sangja, StartButton, Text1, Text2, Text3 } from './components/elements';

const Home: FC<RouteComponentProps> = ({ history }) => {

    // const topic = useSelector<IStore, ITopicState>(store => store.topic);

    const gotoContent = () => {
        history.replace('/content/1');
    };

    return (

        <Container>
            <Sangja />
            <Text1 />
            <Text2 />
            <StartButton onClick={gotoContent} />
            <Text3 />
        </Container>


    );
};

export default Home;