import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ITopicState } from '../types';
import { fetchTopic as fTopic } from '../utils/fetch-data';

const initialState: ITopicState = {
    loading: true,
    data: []
};

export const fetchTopic = createAsyncThunk(
    'topic/load',
    async () => {
        try {
            const res = await fTopic();

            if (res.status) {
                return res.data;
            }
            else {
                return [];
            }
        }
        catch (err) {
            console.log('fetchTopic Error :');
            console.log(err);
            return [];
        }
    }
);

const topic = createSlice({
    name: 'topic',
    initialState,
    reducers: {
        reset: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTopic.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTopic.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
    }
});

export default topic.reducer;
export const { reset } = topic.actions;