import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IStore, IUrlContentParam } from '../../types';
import {
    Container,
    BarContainer,
    Matchine,
    Title,
    ChoiceContainer,
    Choice,
    Radio,
    ProcessContainer,
    ProcessLine,
    ProcessDot,
    NextButton
} from './components/elements';
import { addAnswer } from '../../reducers/answer';

const Content: FC<RouteComponentProps<IUrlContentParam>> = ({ match, history }) => {

    const dispatch = useDispatch();
    const [select, setSelect] = useState<number>(-1);
    const data = useSelector<IStore, IStore>(store => store);

    const topic = data.topic.data[parseInt(match.params.id) - 1];

    const handleNext = () => {

        dispatch(addAnswer(select));
        setSelect(-1);
        
        const index = parseInt(match.params.id) + 1;

        if(index > data.topic.data.length) {
            history.replace('/completed');
        }
        else {
            history.replace(`/content/${index}`);
        }

    };

    return (
        <Container>
            <BarContainer>
                <Matchine />
                <Title>{topic.title}</Title>
                <Matchine />
            </BarContainer>
            <ChoiceContainer>
                {topic.choice.map((c, k) => (
                    <Choice 
                        key={k} 
                        
                        onClick={() => setSelect(k)}
                    >
                        {c}
                        <Radio active={select === k} />
                    </Choice>
                ))}
            </ChoiceContainer>
            <ProcessContainer>
                {data.topic.data.map((_, k) => (
                    <ProcessDot 
                        key={k} 
                        active={k <= parseInt(match.params.id) - 1} 
                    />
                ))}
                <ProcessLine />
            </ProcessContainer>
            {select > -1 && <NextButton onClick={handleNext} />}
        </Container>
    );
};

export default Content;