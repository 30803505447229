import { createGlobalStyle } from 'styled-components';

import fontItim from '../fonts/Itim-Regular.ttf';

const Global = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
    }

    @font-face {
        font-family: "Itim";
        src: url(${fontItim}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    body {
        font-family: "Itim";
        font-size: 16px;
        margin: 0;
        user-select: none;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .page-slide-enter {
        transform: translateX(100%);
    }

    .page-slide-enter-active {
        transform: translateX(0%);
        transition: transform 1s ease-in;
    }

    .page-slide-exit {
        opacity: 1;
    }

    .page-slide-exit-active {
        opacity: 0;
        transition: opacity 1s ease-in;
    }
`;

export default Global;