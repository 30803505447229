import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from '../home/components/elements';
import { Text, BackButton } from './components/elements';
import Loading from '../../components/loading';
import { IStore } from '../../types';
import { resetAnswer } from '../../reducers/answer';
import { saveAnswer } from '../../utils/fetch-data';

const Completed: FC<RouteComponentProps> = ({ history }) => {

    const [loading, setLoading ] = useState<boolean>(true);
    const answer = useSelector<IStore, Array<number>>(state => state.answer);
    const dispatch = useDispatch();

    useEffect(() => {
        saveAnswer(JSON.stringify(answer))
            .then(() => {
                setLoading(false);
                dispatch(resetAnswer());
            });
    }, [dispatch, answer]);

    const handleBack = () => {
        history.replace('/');
    };

    if(loading) {
        return <Loading />
    }

    return (
        <Container>
            <Text />
            <BackButton onClick={handleBack} />
        </Container>
    );
};

export default Completed;