import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Home, Content, Completed } from './screens';

const AppRoute: FC = () => {

    return (

        <Switch>
            <Route path="/" component={Home} exact={true} />
            <Route path="/content/:id" component={Content} exact={true} />
            <Route path="/completed" component={Completed} exact={true} />
        </Switch>
        
    );
};

export default AppRoute;