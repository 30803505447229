import { ITopicResult } from '../types';
export const server = 'https://slri20-api.yourteedin.com/api/v1';

export const fetchTopic = (): Promise<ITopicResult> => {

    return fetch(`${server}/topic`)
        .then(res => res.json());

};

export const saveAnswer = (body: string): Promise<any> => {

    return fetch(`${server}/answer`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body,
    }).then(res => res.json());

};