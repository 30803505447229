import styled from 'styled-components';

import bg1 from '../images/Asset-5.png';
import bg2 from '../images/Asset-6.png';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: url(${bg1}), url(${bg2}), linear-gradient(180deg, #43A0D8 0%, #ACCF3A 100%);
    background-position: top left, bottom right, center;
    background-repeat: no-repeat;
    overflow: auto;
`;

export default Container;