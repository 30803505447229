import React, { FC, HTMLAttributes } from 'react';
import styled, { keyframes } from 'styled-components';

import sangja from '../../../images/sangja.png';
import text1 from '../../../images/Asset-1.png';
import text2 from '../../../images/Asset-2.png';
import text3 from '../../../images/Asset-7.png';
import startButton from '../../../images/Asset-8.png';

import bg1 from '../../../images/Asset-5.png';
import bg2 from '../../../images/Asset-6.png';

const bounce = keyframes`
    0% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.95);
    }
`;

const Sangja_: FC<HTMLAttributes<HTMLImageElement>> = ({ ...props }) => <img src={sangja} {...props} alt="" />;
const Text1_: FC<HTMLAttributes<HTMLImageElement>> = ({ ...props }) => <img src={text1} {...props} alt="" />;
const Text2_: FC<HTMLAttributes<HTMLImageElement>> = ({ ...props }) => <img src={text2} {...props} alt="" />;
const Text3_: FC<HTMLAttributes<HTMLImageElement>> = ({ ...props }) => <img src={text3} {...props} alt="" />;
const StartButton_: FC<HTMLAttributes<HTMLImageElement>> = ({ ...props }) => <img src={startButton} {...props} alt="" />;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: url(${bg1}), url(${bg2}), linear-gradient(180deg, #43A0D8 0%, #ACCF3A 100%);
    background-position: top left, bottom right, center;
    background-repeat: no-repeat;
    overflow: hidden;
`;

export const Sangja = styled(Sangja_)`
    margin-top: 30px;
    margin-bottom: 30px;
`;

export const Text1 = styled(Text1_)``;
export const Text2 = styled(Text2_)``;

export const StartButton = styled(StartButton_)`
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    animation: ${bounce} 2s linear infinite;
    
`;

export const Text3 = styled(Text3_)``;