import React, { FC, useRef, useEffect } from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';
import OriContainer from './container';
import loadingAnime from '../lottie/loading.json';

const EleLoading = styled.div`
    width: 140px;
    height: 140px;
`;

const Container = styled(OriContainer)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Loaing: FC = () => {

    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (divRef.current) {
            lottie.loadAnimation({
                container: divRef.current,
                loop: true,
                renderer: 'canvas',
                autoplay: true,
                animationData: loadingAnime,
            });
        }
    }, []);

    return (
        <Container>
            <EleLoading ref={divRef} />
        </Container>
    );
};

export default Loaing;