import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import GlobalStyle from './components/global-style';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';

ReactDOM.render(
    <React.StrictMode>
        <StoreProvider store={store}>
            <Router>
                <GlobalStyle />
                <App />
            </Router>
        </StoreProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

document.oncontextmenu = (e) => e.preventDefault();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
