import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import back from '../../../images/back.png';
import text1 from '../../../images/success-text.png';

const ImgText: FC<HTMLAttributes<HTMLImageElement>> = ({ ...props }) => <img src={text1} { ...props } alt="" />;

export const Text = styled(ImgText)`
    display: block;
    margin-bottom: 50px;
`;

export const BackButton = styled.button`
    display: block;
    border: none;
    outline: none;
    background-image: url(${back});
    background-color: transparent;
    width: 399px;
    height: 80px;
`;